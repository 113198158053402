
  import * as ACCOUNT from '../../api/account.js';
  import * as ACC_Util from '../../utils/account.js';
  import type { Rule } from 'ant-design-vue/es/form';
  import { defineComponent, reactive, ref, toRefs, onMounted } from 'vue';
  import { message } from 'ant-design-vue';
  import router from '@/router';
  import type { FormInstance } from 'ant-design-vue';
  interface FormState {
    password: string;
    checkPassword: string;
    code: string;
  }
  export default defineComponent({
    name: 'ResetPassword',
    setup(props) {
      const formRef = ref<FormInstance>()
      const formState = reactive<FormState>({
        password: '',
        checkPassword: '',
        code: '',
      });

      // const codeBtn = ref('获取验证码')
      // const btnDisable = ref(false)
      const ctDownState = reactive({
        countTime: 60,
        timer: -1,
        counting: false,
        codeBtn: '获取验证码',
        btnDisable: false,
      })
      const countDown = () => {
        let startTime = Number(localStorage.getItem('r-countDown'))
        let nowTime = new Date().getTime()
        if(startTime) {
          let surplus = 60 - Math.floor((nowTime - startTime)/1000)
          ctDownState.countTime = surplus <= 0 ? 0 : surplus
        }else {
          ctDownState.countTime = 60
          localStorage.setItem('r-countDown', String(nowTime))
        }
        ctDownState.timer = setInterval(()=>{
          ctDownState.countTime -= 1
          ctDownState.btnDisable = true
          ctDownState.codeBtn = `${ctDownState.countTime}s`
          if(ctDownState.countTime <=0){
            localStorage.removeItem('r-countDown')
            clearInterval(ctDownState.timer)
            ctDownState.countTime = 60
            ctDownState.btnDisable = false
            ctDownState.codeBtn = '获取验证码'
          }
        }, 1000)
      };
      onMounted(() => {
        let sendTime = localStorage.getItem('r-countDown')
        if(sendTime){
          countDown()
        }
      })
      let validatePassword = async (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('密码不允许为空')
        } else if (value.length < 6 || value.length > 16) {
          return Promise.reject('密码长度在6至16位之间')
        } else {
          if (formState.checkPassword !== '') {
            formRef!.value!.validateFields('checkPassword')
          }
          return Promise.resolve()
        }
      };
      let validateCheckPassword = async  (_rule: Rule, value: string) => {
        if (value === '') {
          return Promise.reject('校验密码不允许为空')
        } else if (value !== formState.password) {
          return Promise.reject("两次输入的密码不一致")
        } else {
          return Promise.resolve()
        }
      };
      let validateCode = async  (_rule: Rule, value: string) => {
        let regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。
        if (value === '') {
          return Promise.reject('验证码不允许为空')
        } else if (!regPos.test(value)) {
          return Promise.reject("验证码格式错误")
        } else {
          return Promise.resolve()
        }
      };
      const resetForm = () => {
        formRef!.value!.resetFields();
      };
      //
      const handleFinish = () => {
        let params = new FormData()
        params.append('username', ACC_Util.getUserName())
        params.append('password', formState.password)
        params.append('code', formState.code)
        console.log(formState)
        ACCOUNT.resetPassword(params).then(res=>{
          // console.log('rst pswd', res)
          if(res.data.code !== 200) {
            message.warning(res.data.msg)
          }else {
            message.info(res.data.msg)
            router.push({
              path: '/login'
            })
            localStorage.removeItem('userToken')
          }
        })
      }
      const sendCode = (formName: any) => {
        if(formState.checkPassword == '' || formState.password == '') {
          message.warning('请输入新密码')
          return;
        }
        let params = new FormData()
        params.append('username', ACC_Util.getUserName())
        ACCOUNT.getMailCode(params).then(res=>{
          // console.log('mail', res)
          if(res.data.code == 200) {
            if(res.data.data == 202) {
              message.warning('绑定邮箱有误哦')
            }else {
              countDown()
            }
          }
        })
      }
      const rules: Record<string, Rule[]> = {
        password: [{ required: true, validator: validatePassword, trigger: 'change' }],
        checkPassword: [{ required: true, validator: validateCheckPassword, trigger: 'change' }],
        code: [{ required: true, validator: validateCode, trigger: 'change' }]
      };
      const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      };
      //

      return {
        formRef,
        formState,
        rules,
        layout,
        handleFinish,
        resetForm,
        sendCode,
        ...toRefs(ctDownState)
      }
    }
  })
